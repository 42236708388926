<template>
	<header :class="headerClasses()">
		<b-container fluid class="px-3 px-lg-2">
			<b-row align-v="center" class="no-gutter m-0 py-5" :class="live ? 'border-bottom' : ''">
				<b-col cols="auto" v-if="getLogo()" class="px-1 pl-lg-8">
					<img :src="getLogo()" :style="channel.skin_header_logo_height ? 'height: ' + channel.skin_header_logo_height : null" />
				</b-col>
				<b-col cols="auto" class="ml-auto text-md-right pr-1 pr-lg-8" v-if="showCalendar()">
					<ul class="menu">
						<li>
							<a 	:href="asset.calendar_url"
								target="_blank"
								class="small"
								:title="$t('common.add-reminder')">
								<span class="d-block d-lg-none small">{{ $t('common.reminder') }}</span>
								<span class="d-none d-lg-block small">{{ $t('common.add-reminder') }}</span>
							</a>
						</li>
					</ul>
				</b-col>
				<b-col cols="auto" class="ml-auto text-md-right pr-1 pr-lg-8" v-else-if="useMenu()">
					<a 	href="#"
						class="toggle"
						:class="sidebar ? 'visible' : null"
						@click.prevent="toggleSidebar(getSidebarVal())">
						<span :class="toggleClasses()"></span>
						<span :class="toggleClasses()"></span>
						<span :class="toggleClasses()"></span>
					</a>
					<ul class="menu live">
						<li v-if="showAsk()">
							<a 	href="#"
							@click.prevent="asset.asset_qa && asset.asset_qa.sidebar && sidebar === 'qa' ? toggleSidebar() : asset.asset_qa.sidebar ? toggleSidebar('qa') :  $scrollTo('#ask')"
								class="small"
								:title="$t('common.ask-question')">
								{{ asset.asset_qa.headline ? asset.asset_qa.headline : $t('common.ask-question') }}
							</a>
						</li>
						<li v-if="showChat()">
							<a 	href="#"
								@click.prevent="sidebar === 'chat' ? toggleSidebar() : toggleSidebar('chat')"
								class="small"
								:title="$t('common.chat')">
								{{ $t('common.chat') }}
							</a>
						</li>
						<li v-if="showPoll()">
							<a 	href="#"
								@click.prevent="sidebar === 'poll' ? toggleSidebar() : toggleSidebar('poll')"
								class="small"
								:title="$t('common.poll')">
								{{ $t('common.poll') }}
							</a>
						</li>
						<li v-if="showQueButtons()">
							<a 	href="#"
								@click.prevent="$bvModal.show('addQuestionModal')"
								class="small"
								:title="$t('questions.add-question')">
								{{ $t('questions.add-question') }}
							</a>
						</li>
						<li v-if="showQueButtons() && questions && questions.moderator && questions.moderator.length">
							<download-excel :data="questions.moderator"
											:fields="exportFields"
											type="xls"
											:name="genFilename(asset.title) + '.xls'">
								<a 	href="#"
									@click.prevent="null"
									class="small"
									:title="$t('questions.export-data')">
									{{ $t('questions.export-data') }}
								</a>
							</download-excel>
						</li>
					</ul>
				</b-col>
			</b-row>
		</b-container>
	</header>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			category() {
				return this.$store.getters.category
			},
			channel() {
				return this.$store.getters.channel
			},
			questionPassword() {
				if(this.$store.getters.questionPassword) return this.$store.getters.questionPassword
				if(sessionStorage.getItem('questionPassword')) return sessionStorage.getItem('questionPassword')
				return null
			},
			questionType() {
				return this.$store.getters.questionType
			},
			questions() {
				return this.$store.getters.questions
			},
			sidebar() {
				return this.$store.getters.sidebar
			},
			prelive() {
				if(this.asset && this.asset.broadcasting !== null && this.asset.broadcasting === 0) return 'prelive'
				return false
			},
			live() {
				if(this.asset && this.asset.broadcasting !== null && this.asset.broadcasting === 1) return 'live'
				return false
			}
		},
		data() {
			return {
				exportFields: {
					"Full name": "name",
					"Title": "title",
					"Company": "company",
					"Phone": "phone",
					"E-mail": "email",
					"Question": "text",
					"Created": "created_at",
					"Updated": "updated_at"
				}
			}
		},
		methods: {
			getLogo() {
				return this.channel && this.channel.logos && this.channel.logos.length && this.channel.logos_path ? this.channel.logos_path + this.channel.logos[0].filename : null
			},
			showCalendar() {
				if ((this.prelive && this.$route.name !== 'preview') && this.asset.is_calendar_reminder && this.asset.calendar_url && this.$route.name !== 'questions') return true
				return false
			},
			showAsk() {
				if ((this.live || this.$route.name === 'preview') && this.asset.asset_qa && this.asset.asset_qa.enabled && this.$route.name !== 'questions') return true
				return false
			},
			showChat() {
				if((this.live || this.$route.name === 'preview') && this.asset.chat_url && this.$route.name !== 'questions') return true
				return false
			},
			showPoll() {
				if ((this.live || this.$route.name === 'preview') && this.asset.poll_url && this.$route.name !== 'questions') return true
				return false
			},
			showQueButtons() {
				if(this.$route.name === 'questions' && this.questionPassword && this.questionType && this.questionType === 'moderator') return true
				return false
			},
			genFilename(title) {
				title = title.replace(/ /g, '_')
				const moment = this.$moment
				const now = moment().format('YYYY-MM-DD_hh_mm_ss')
				title = title + '_' + now
				return title
			},
			toggleSidebar(val) {
				this.$store.dispatch('sidebar', val)
			},
			useMenu() {
				if(this.showAsk()) return true
				if(this.showChat()) return true
				if(this.showPoll()) return true
				if(this.showQueButtons()) return true
				return false
			},
			headerClasses() {
				return [
					this.channel && this.channel.skin_background_header ? 'bg-'+this.channel.skin_background_header : 'bg-light',
					this.channel && this.channel.skin_header_text ? 'text-'+this.channel.skin_header_text : 'text-dark'
				]
			},
			toggleClasses() {
				return [
					this.channel && this.channel.skin_header_text ? 'bg-'+this.channel.skin_header_text : 'bg-dark'
				]
			},
			getSidebarVal() {
				if(this.asset.asset_qa && this.asset.asset_qa.enabled && this.asset.asset_qa.sidebar) return 'qa'
				if(this.asset.chat_url) return 'chat'
				if(this.asset.poll_url) return 'poll'
				return null
			}
		}
	}
</script>
