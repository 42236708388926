import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: 'channel',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Channel.vue')
	},
	{
		path: '/category/:slug?',
		name: 'category',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Category.vue')
	},
	{
		path: '/questions/:slug?',
		name: 'questions',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Questions.vue')
	},
	{
		path: '/preview/:slug?',
		name: 'preview',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Preview.vue')
	},
	{
		path: '/asset/:slug?',
		name: 'asset',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Asset.vue')
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/NotFound.vue')
	},
	{
		path: '/:a?/:b?/:c?/:d?/:e?/:f?/:g?/:h?/:i?/:slug',
		name: 'type',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Type.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
